import { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyIcon from '@mui/icons-material/Key';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { apiConfig } from "../authConfig";
import { acquireAccessToken } from "./MsalHelper";
import { IDevice } from "../interfaces/IDevice";
import { KeyType } from '../enums/KeyType';
import DialogHeader from './DialogHeader';

interface KeyDialogProps {
    device: IDevice | undefined;
    onClose(): any;
    showDialog: boolean;
    keyType: KeyType | null;
}

export default function KeyDialog(props: KeyDialogProps) {
    const { instance } = useMsal();

    const [key, setKey] = useState<string>();

    useEffect(() => {
        if (props.device && props.showDialog) {
            acquireAccessToken(instance).then(accessToken => {
                const headers = new Headers();
                const bearer = `Bearer ${accessToken}`;

                headers.append("Authorization", bearer);

                const options: RequestInit = {
                    method: "GET",
                    mode: "cors",
                    headers: headers
                };

                if (props.keyType === KeyType.LocalAdminPwd) {
                    fetch(`${apiConfig.apis.azureAd}/LocalAdminPassword?deviceObjectId=${props.device?.id}&deviceName=${props.device?.displayName}`, options).then((value) => {
                        return value.text();
                    }).then((passwordBase64: string) => {
                        if (passwordBase64) {
                            setKey(atob(passwordBase64));
                        } else {
                            setKey("-");
                        }
                    }).catch(_ => setKey("-"));
                } else if (props.keyType === KeyType.RecoveryKey) {
                    fetch(`${apiConfig.apis.azureAd}/RecoveryKey?deviceObjectId=${props.device?.id}&deviceName=${props.device?.displayName}`, options).then((value) => {
                        return value.text();
                    }).then((recoveryKey: string) => {
                        if (recoveryKey) {
                            setKey(recoveryKey);
                        } else {
                            setKey("-");
                        }
                    }).catch(_ => setKey("-"));
                } else {
                    // Not implemented
                }
            });
        } else {
            setKey(undefined);
        }
    }, [props.device, props.showDialog]);

    return (
        <Dialog open={props.showDialog} onClose={() => props.onClose()}>
            <DialogTitle>
                <DialogHeader device={props.device} icon={props.keyType === KeyType.LocalAdminPwd ? <KeyIcon /> : <LockOpenIcon />} title={props.keyType === KeyType.LocalAdminPwd ? "Password" : "Recovery key"} />
            </DialogTitle>
            <DialogContent sx={{ paddingBottom: 1 }}>
                <FormControl sx={{ marginTop: 2, width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-key">Value</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-key"
                        label="Value"
                        value={key ? key : "loading"}
                        type={'text'}
                        multiline
                        sx={{ fontFamily: "Verdana" }} // Font with distinguishable uppercase I and lowercase L
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    title="Copy to clipboard"
                                    onClick={() => navigator.clipboard.writeText(key || "")}
                                    edge="end"
                                    disabled={!key}
                                >
                                    <ContentCopyIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => props.onClose()}
                >Close</Button>
            </DialogActions>
        </Dialog>
    );
}