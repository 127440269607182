const webAppUrl = "https://bcm.blum.com";
const apiUrl = "https://oaps001.blum.com/BCM";
//const webAppUrl = "http://localhost:3002/";
//const apiUrl = "https://localhost:7287";
export const helpUrl = "https://confluence.blum.com/x/6jz5NQ";
export const logsUrl = "https://app.powerbi.com/groups/a8e6405a-59b7-41f4-817d-59778c08fec6/reports/0fdbdd8c-5f73-4351-a30e-8fc44286f131";

export const msalConfig = {
  auth: {
    clientId: "a8354308-6a6a-4dd9-be99-4604a759a00b",
    authority: "https://login.microsoftonline.com/8589a678-2713-47f3-b985-ea77d248aba8", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: webAppUrl,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  myPhotoEndpoint: "https://graph.microsoft.com/v1.0/me/photo/$value",
  scopes: ["User.Read"]
};

export const apiConfig = {
  appRoles: {
    admin: "BCM.Admin",
    deviceLifecycleManager: "Device.CreateDelete"
  },
  scopes: ["api://a8354308-6a6a-4dd9-be99-4604a759a00b/user_impersonation"],
  apis: {
    azureAd: `${apiUrl}/api/AzureAd`,
    deviceManager: `${apiUrl}/api/DeviceManager`,
    devices: `${apiUrl}/api/Devices`,
    firewall: `${apiUrl}/api/Firewall`,
    groups: `${apiUrl}/api/Groups`,
    organizationalUnits: `${apiUrl}/api/OrganizationalUnits`,
    software: `${apiUrl}/api/Software`,
    windows11: `${apiUrl}/api/Windows11`,
    windowsHello: `${apiUrl}/api/WindowsHello`,
    windowsPilot: `${apiUrl}/api/WindowsPilot`
  }
};