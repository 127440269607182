import * as React from 'react';
import { useState, useEffect } from 'react';
import { Alert, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ShieldTwoToneIcon from '@mui/icons-material/ShieldTwoTone';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { apiConfig } from '../authConfig';
import { acquireAccessToken } from './MsalHelper';
import { useMsal } from '@azure/msal-react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { IDevice } from '../interfaces/IDevice';
import { IFirewallSettings } from '../interfaces/IFirewallSettings';
import { IFirewallSettingsChangeRequest } from '../interfaces/IFirewallSettingsChangeRequest';
import DialogHeader from './DialogHeader';
import { IDeviceBase } from '../interfaces/IDeviceBase';
import { TrustType } from '../enums/TrustType';

interface FirewallDialogProps {
    device: IDevice | null | undefined;
    onClose(): any;
    showDialog: boolean;
};

enum Status {
    NONE,
    IN_PROGRESS,
    ERROR
};

export default function FirewallDialog(props: FirewallDialogProps) {
    const { instance } = useMsal();

    const [currentFirewallSettings, setCurrentFirewallSettings] = useState<IFirewallSettings>();
    const [newFirewallSettings, setNewFirewallSettings] = useState<IFirewallSettings>();
    const [settingsChanged, setSettingsChanged] = useState<boolean>(false);
    const [status, setStatus] = useState<Status>(Status.NONE);

    useEffect(() => {
        setNewFirewallSettings(currentFirewallSettings);
        setSettingsChanged(false);
    }, [currentFirewallSettings]);

    // Check if values changed to only enable Save button when there is an update.
    useEffect(() => {
        setStatus(Status.NONE);

        if ((currentFirewallSettings && newFirewallSettings) && (
            currentFirewallSettings.applyLocalRules !== newFirewallSettings.applyLocalRules ||
            currentFirewallSettings.disableFirewall !== newFirewallSettings.disableFirewall ||
            currentFirewallSettings.localFirewallRule !== newFirewallSettings.localFirewallRule ||
            currentFirewallSettings.prolager !== newFirewallSettings.prolager ||
            currentFirewallSettings.softwareDeveloper !== newFirewallSettings.softwareDeveloper)
        ) {
            setSettingsChanged(true);
        } else {
            setSettingsChanged(false);
        }
    }, [newFirewallSettings]);

    useEffect(() => {
        if (props.device && props.showDialog) {
            acquireAccessToken(instance).then(accessToken => {
                const headers = new Headers();
                const bearer = `Bearer ${accessToken}`;

                headers.append("Authorization", bearer);

                const options: RequestInit = {
                    method: "GET",
                    mode: "cors",
                    headers: headers
                };

                fetch(`${apiConfig.apis.firewall}?hsvName=${props.device?.hsvInfos?.hsvName}&deviceId=${props.device?.id}&trustType=${props.device?.trustType}`, options).then((value) => {
                    return value.json();
                }).then((response: IFirewallSettings) => {
                    setCurrentFirewallSettings(response);
                });
            });
        } else {
            setCurrentFirewallSettings(undefined);
        }
    }, [props.device, props.showDialog]);

    const updateFirewallSettings = () => {
        setStatus(Status.IN_PROGRESS);
        acquireAccessToken(instance).then(accessToken => {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);
            headers.append('Content-type', 'application/json');

            const options: RequestInit = {
                method: "POST",
                mode: "cors",
                headers: headers,
                body: JSON.stringify({
                    device: {
                        displayName: props.device?.displayName,
                        id: props.device?.id,
                        trustType: props.device?.trustType
                    } as IDeviceBase,
                    firewallSettings: newFirewallSettings
                } as IFirewallSettingsChangeRequest)
            };

            fetch(`${apiConfig.apis.firewall}`, options).then((value) => {
                if (value.ok) {
                    setCurrentFirewallSettings(newFirewallSettings);
                    setStatus(Status.NONE);
                } else {
                    setStatus(Status.ERROR);
                }
            }).catch(() => {
                setStatus(Status.ERROR);
            });
        });
    };

    return (
        <Dialog open={props.showDialog} onClose={() => props.onClose()}>
            <DialogTitle>
                <DialogHeader device={props.device} icon={<ShieldTwoToneIcon />} title="Firewall settings" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: 1 }}>
                    Select firewall policy to apply for computer.
                </DialogContentText>
                {!newFirewallSettings ? <Box sx={{ display: 'flex', margin: 5, flexDirection: 'column', alignItems: 'center' }}>
                    <CircularProgress />
                </Box> : <>
                    <List>
                        <ListItem sx={{ padding: 0 }}>
                            <ListItemButton
                                role={undefined}
                                onClick={() => setNewFirewallSettings({ ...newFirewallSettings, applyLocalRules: !newFirewallSettings.applyLocalRules })}
                                sx={{ padding: 0 }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={newFirewallSettings?.applyLocalRules}
                                        tabIndex={-1}
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Apply Local Rules" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={{ padding: 0 }}>
                            <ListItemButton
                                role={undefined}
                                onClick={() => setNewFirewallSettings({ ...newFirewallSettings, disableFirewall: !newFirewallSettings.disableFirewall })}
                                sx={{ padding: 0 }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={newFirewallSettings?.disableFirewall}
                                        tabIndex={-1}
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Disable Firewall" />
                            </ListItemButton>
                        </ListItem>
                        {props.device?.trustType !== TrustType.AzureAd && <>
                            <ListItem sx={{ padding: 0 }}>
                                <ListItemButton
                                    role={undefined}
                                    onClick={() => setNewFirewallSettings({ ...newFirewallSettings, prolager: !newFirewallSettings.prolager })}
                                    sx={{ padding: 0 }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={newFirewallSettings?.prolager}
                                            tabIndex={-1}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Prolager" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem sx={{ padding: 0 }}>
                                <ListItemButton
                                    role={undefined}
                                    onClick={() => setNewFirewallSettings({ ...newFirewallSettings, softwareDeveloper: !newFirewallSettings.softwareDeveloper })}
                                    sx={{ padding: 0 }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={newFirewallSettings?.softwareDeveloper}
                                            tabIndex={-1}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Software Developer" />
                                </ListItemButton>
                            </ListItem>
                        </>}
                    </List>

                    <FormControl sx={{ marginTop: 2, width: '100%' }} variant="outlined" disabled={!newFirewallSettings.applyLocalRules}>
                        <InputLabel htmlFor="outlined-adornment-localrule">Firewall local rule</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-localrule"
                            label="Firewall local rule"
                            value={newFirewallSettings?.localFirewallRule}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewFirewallSettings({ ...newFirewallSettings, localFirewallRule: event.target.value })}
                            type={'text'}
                            multiline
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        title="Copy to clipboard"
                                        onClick={() => navigator.clipboard.writeText(newFirewallSettings.localFirewallRule)}
                                        edge="end"
                                        disabled={!newFirewallSettings.localFirewallRule}
                                    >
                                        <ContentCopyIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </>}
                {status === Status.ERROR && <Alert severity="error" sx={{ marginTop: 2 }}>Changes could not be saved.</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()}>Close</Button>
                <Button
                    disabled={!newFirewallSettings || !settingsChanged || status === Status.IN_PROGRESS}
                    onClick={() => updateFirewallSettings()}
                >{settingsChanged ? "Apply changes" : "Saved"}</Button>
            </DialogActions>
        </Dialog>
    )
}