import { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, TextField, Typography } from "@mui/material";

interface SoftwareConfirmDialogProps {
    action: "installed" | "uninstalled";
    onApprove(ticketNumber: string): any;
    onReject(): any;
    open: boolean;
    requireTicket: boolean;
    selectedSoftwareNames: string[];
};

export default function SoftwareConfirmDialog(props: SoftwareConfirmDialogProps) {
    const [ticketNumber, setTicketNumber] = useState<string>("");

    useEffect(() => {
        setTicketNumber("");
    }, [props.open]);

    return (
        <Dialog open={props.open} onClose={() => props.onReject()} maxWidth='lg' scroll='paper'>
            <DialogContent>
                <Typography>
                    The following software will be {props.action}:
                </Typography>
                <ul>
                    {props.selectedSoftwareNames.map(s => <li>{s}</li>)}
                </ul>
                <TextField
                    id="ticket-number"
                    label="Related ticket number"
                    variant="standard"
                    value={ticketNumber}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTicketNumber(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => props.onReject()}>
                    Cancel
                </Button>
                <Button
                    onClick={() => props.onApprove(ticketNumber)}
                    disabled={ticketNumber.length < 1 && props.requireTicket}
                >Ok</Button>
            </DialogActions>
        </Dialog>
    )
}