import { Search, SearchIconWrapper, StyledInputBase } from "./StyledSearch";
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";

interface GlobalSearchFieldProps {
    activeSearchTerm: string;
    onSearchExecuted: any;
}

export default function GlobalSearchField(props: GlobalSearchFieldProps) {
    const [searchFieldValue, setSearchFieldValue] = useState<string>("");

    useEffect(() => {
        setSearchFieldValue(props.activeSearchTerm);
    }, [props.activeSearchTerm]);

    return <Search>
        <SearchIconWrapper>
            <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
            placeholder="Search..."
            inputProps={{ 'aria-label': 'search' }}
            value={searchFieldValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearchFieldValue(event.target.value);
            }}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    //setMode("Search");
                    props.onSearchExecuted(searchFieldValue);
                }
            }}
        />
    </Search>
}