import { useEffect, useState } from "react";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Switch, Tab, TextField, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { acquireAccessToken } from "./MsalHelper";
import { apiConfig } from "../authConfig";
import { useMsal } from "@azure/msal-react";
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { ICreateComputersRequest } from "../interfaces/ICreateComputersRequest";
import { ICreateComputerOperation } from "../interfaces/ICreateComputerOperation";
import { CreationStatus } from "../enums/CreationStatus";
import OsImageDropdown from "./OsImageDropdown";
import { IOsImage } from "../interfaces/IOsImage";

interface AddDeviceDialogProps {
    organizationalUnit: string;
    showDialog: boolean;
    onClose(): any;
}

const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export default function AddDeviceDialog(props: AddDeviceDialogProps) {
    const { instance } = useMsal();

    // Input states
    const [mode, setMode] = useState<"Single" | "Multi">("Single");
    const [deviceNamesInput, setDeviceNamesInput] = useState<string>("");
    const [selectedOsImage, setSelectedOsImage] = useState<IOsImage>();
    const [isBiosGuidMode, setIsBiosGuidMode] = useState<boolean>(false);
    const [biosGuid, setBiosGuid] = useState<string>("");

    // Status and error messages
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // Data requested
    const [createComputerOperations, setCreateComputerOperations] = useState<ICreateComputerOperation[]>([]);

    // Validation states
    const [isBiosGuidValid, setIsBiosGuidValid] = useState<boolean>(false);

    useEffect(() => {
        setDeviceNamesInput("");
        setMode("Single");
        setErrorMessage(null);
        setIsBiosGuidMode(false);
        setSelectedOsImage(undefined);
    }, [props.showDialog]);

    useEffect(() => {
        setErrorMessage(null);
        setCreateComputerOperations([]);
    }, [deviceNamesInput]);

    useEffect(() => {
        setDeviceNamesInput("");
        setIsBiosGuidMode(false);
    }, [mode]);

    useEffect(() => {
        setBiosGuid("");
    }, [isBiosGuidMode]);

    useEffect(() => {
        setIsBiosGuidValid(guidPattern.test(biosGuid));
    }, [biosGuid]);

    const validateDeviceNames = () => {
        setIsLoading(true);
        setErrorMessage(null);

        acquireAccessToken(instance).then(accessToken => {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);
            headers.append('Content-type', 'application/json');

            const options: RequestInit = {
                method: "GET",
                mode: "cors",
                headers: headers
            };

            var deviceNamesParameter = "";

            if (mode === "Multi") {
                var deviceNames: string[] = deviceNamesInput
                    .toUpperCase()
                    .split(/\n/) // split lines into array
                    .filter(e => e) // remove empty items
                    .map(e => e.trim()) // trim whitespaces
                    .filter((v, i, a) => a.indexOf(v) === i); // remove duplicates

                deviceNamesParameter = deviceNames.map(c => `deviceNames=${c}`).join("&");
            } else {
                deviceNamesParameter = `deviceNames=${deviceNamesInput.toUpperCase().trim()}`;
            }

            fetch(`${apiConfig.apis.deviceManager}/ValidateNewComputers?${deviceNamesParameter}&organizationalUnit=${props.organizationalUnit}`, options)
                .then((response) => {
                    if (response.ok) {
                        response.json().then((validationResult: ICreateComputerOperation[]) => {
                            // Preselect entry if there is only one.
                            validationResult.forEach(e => {
                                if (e.macAddresses?.length === 1) {
                                    e.selectedMacAddress = e.macAddresses[0].macAddress;
                                }
                            });

                            setCreateComputerOperations(validationResult);
                        });
                    } else {
                        response.json().then(data => {
                            setErrorMessage(data.detail);
                        }).catch(() => {
                            setErrorMessage(`Validation failed (status ${response.status}).`);
                        });
                    }
                }).catch(() => {
                    setErrorMessage("Validation failed.");
                }).finally(() => {
                    setIsLoading(false);
                });
        });
    };

    const addComputers = () => {
        // check loading state in case button disabling is deferred
        if (isLoading) {
            return;
        }

        setIsLoading(true);

        acquireAccessToken(instance).then(accessToken => {
            if (!selectedOsImage) return;

            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);
            headers.append('Content-type', 'application/json');

            var body: ICreateComputersRequest = {
                devices: createComputerOperations.map((c, idx) => { return { deviceName: c.deviceName, macAddress: c.selectedMacAddress } }),
                organizationalUnit: props.organizationalUnit,
                biosGuid: biosGuid ? biosGuid : null,
                osImageCollectionId: selectedOsImage.collectionId
            }

            const options: RequestInit = {
                method: "POST",
                mode: "cors",
                headers: headers,
                body: JSON.stringify(body)
            };

            fetch(`${apiConfig.apis.deviceManager}/CreateComputers`, options).then((response) => {
                if (response.ok) {
                    response.json().then((validationResult: ICreateComputerOperation[]) => {
                        setCreateComputerOperations(validationResult);
                    });
                } else {
                    response.json().then(data => {
                        setErrorMessage(data.detail);
                    }).catch(() => {
                        setErrorMessage(`${response.statusText} (${response.status})`);
                    });
                }
            }).catch(() => {
                setErrorMessage("Action failed.");
            }).finally(() => {
                setIsLoading(false);
            });
        });
    };

    return (
        <Dialog open={props.showDialog} onClose={() => { if (!isLoading) { props.onClose(); } }}>
            <DialogTitle>
                New computer
            </DialogTitle>
            <DialogContent sx={{ paddingBottom: 1 }}>
                <TabContext value={mode}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: 3 }}>
                        <TabList onChange={(event: React.SyntheticEvent, newValue: string) => setMode(newValue as any)}>
                            <Tab label="Single" value="Single" />
                            <Tab label="Multiple" value="Multi" />
                        </TabList>
                    </Box>
                </TabContext>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", marginTop: 1, marginBottom: 3 }}>
                        <TextField
                            id="outlined-basic"
                            label={mode === "Multi" ? "Computer names" : "Computer name"}
                            placeholder={mode === "Single" ? "CXX0001" : `CXX0001
CXX0002
CXX0003`}
                            multiline={mode === "Multi"}
                            rows={8}
                            value={deviceNamesInput}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDeviceNamesInput(event.target.value)}
                            InputLabelProps={{ shrink: true }}
                            autoComplete='off'
                            sx={{ width: "100%" }}
                        />
                        <Button
                            onClick={() => validateDeviceNames()}
                            disabled={isLoading || deviceNamesInput.length < 1}
                            sx={{ marginLeft: 1 }}
                        >Check</Button>
                    </Box>
                    <OsImageDropdown
                        organizationalUnit={props.organizationalUnit}
                        selectedOsImage={selectedOsImage}
                        setSelectedOsImage={(osImage) => setSelectedOsImage(osImage)}
                    />
                    <TabContext value={mode}>
                        <TabPanel value="Single" sx={{ padding: 0 }}>
                            <FormGroup>
                                <FormControlLabel
                                    label="Create via BIOS GUID"
                                    control={
                                        <Switch
                                            checked={isBiosGuidMode}
                                            onChange={() => setIsBiosGuidMode(!isBiosGuidMode)}
                                        />
                                    }
                                />
                            </FormGroup>
                        </TabPanel>
                    </TabContext>
                    {isBiosGuidMode && <TextField
                        error={!isBiosGuidValid}
                        label="BIOS GUID"
                        value={biosGuid}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setBiosGuid(event.target.value)}
                        InputLabelProps={{ shrink: true }}
                        sx={{ marginTop: 3, marginBottom: 3 }}
                    />
                    }
                </Box>
                <List>{createComputerOperations.map((i, index) => {
                    return <>
                        <ListItem sx={{ padding: 0 }}>
                            <ListItemIcon>
                                {i.creationStatus === CreationStatus.READY_TO_CREATE ? <CheckIcon color="success" /> :
                                    i.creationStatus === CreationStatus.CREATED ? <CheckCircleIcon color="success" /> :
                                        <PriorityHighIcon color="error" />}
                            </ListItemIcon>
                            <ListItemText
                                sx={{ whiteSpace: "pre-line" }}
                                primary={i.deviceName}
                                secondary={i.creationStatus === CreationStatus.READY_TO_CREATE ? 'Validation successful.' :
                                    i.creationStatus === CreationStatus.CREATED ? `Creation successful.
MAC address: ${i.selectedMacAddress ? i.selectedMacAddress : 'No MAC address'}` :
                                        <Typography color={'error.main'}>{i.error}</Typography>}
                            />
                        </ListItem>
                        {i.creationStatus === CreationStatus.READY_TO_CREATE && <ListItem sx={{ padding: 0 }}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <FormControl fullWidth variant="standard" sx={{ paddingBottom: 2 }} error={!i.selectedMacAddress}>
                                <InputLabel id="os-select-label">MAC address</InputLabel>
                                <Select
                                    labelId="os-select-label"
                                    value={i.selectedMacAddress || ""}
                                    label="MAC address"
                                    onChange={(event) => {
                                        setCreateComputerOperations(prevItems =>
                                            prevItems.map(item =>
                                                item.deviceName === i.deviceName ? { ...item, selectedMacAddress: event.target.value } : item
                                            )
                                        );
                                    }}
                                    disabled={i.macAddresses.length < 2}
                                >
                                    {i.macAddresses.map(i => {
                                        return <MenuItem key={i.macAddress} value={i.macAddress}>
                                            <ListItemText
                                                primary={i.macAddress}
                                                secondary={i.remark ? `(${i.remark})` : ""}
                                            ></ListItemText>
                                        </MenuItem>
                                    })}
                                </Select>
                                {!i.selectedMacAddress && <FormHelperText>Select a MAC address</FormHelperText>}
                            </FormControl>
                        </ListItem>}
                        <Divider />
                    </>
                })}</List>
                {errorMessage && <Alert severity="error" sx={{ whiteSpace: "pre-line" }}>{errorMessage}</Alert>}
            </DialogContent>
            <DialogActions>
                {createComputerOperations.filter(i => i.creationStatus === CreationStatus.CREATED).length < 1 && <LoadingButton
                    loading={isLoading}
                    loadingPosition="start"
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => addComputers()}
                    disabled={
                        createComputerOperations.length < 1 // disable if no validated computers
                        || createComputerOperations.filter(i => i.creationStatus !== CreationStatus.READY_TO_CREATE).length > 0 // disable if not all computers in this variable are at status validated
                        || (mode === "Single" && (createComputerOperations[0].macAddresses.length < 1 && !isBiosGuidMode)) // disable if single mode and no mac address and no bios guid
                        || (isBiosGuidMode && !isBiosGuidValid) // disable if bios guid mode and invalid bios guid
                        || !selectedOsImage // disable if no os image is selected
                        || (!isBiosGuidMode && createComputerOperations.some(x => !x.selectedMacAddress)) // disable if there is a computer with unselected mac address
                    }
                >Create</LoadingButton>}
                <Button
                    onClick={() => props.onClose()}
                    disabled={isLoading}
                >Close</Button>
            </DialogActions>
        </Dialog>
    )
}