import { useEffect, useState } from "react";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { apiConfig } from "../authConfig";
import { acquireAccessToken } from "./MsalHelper";

interface WindowsUpdatePilotSwitchProps {
    deviceName: string | null | undefined;
};

export default function WindowsUpdatePilotSwitch(props: WindowsUpdatePilotSwitchProps) {
    const { instance } = useMsal();
    const [enabled, setEnabled] = useState<boolean | null>();

    useEffect(() => {
        setEnabled(undefined);

        if (props.deviceName) {
            isEnabled();
        }
    }, [props.deviceName]);

    const isEnabled = () => {
        acquireAccessToken(instance).then(accessToken => {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);

            const options: RequestInit = {
                method: "GET",
                mode: "cors",
                headers: headers
            };

            fetch(`${apiConfig.apis.windowsPilot}/${props.deviceName}`, options).then((value) => {
                if (value.ok) {
                    return value.json();
                } else {
                    throw Error;
                }
            }).then((response: boolean) => {
                setEnabled(response);
            }).catch(() => {
                setEnabled(null);
            });
        });
    };

    const changeEnabled = (enable: boolean) => {
        setEnabled(undefined);

        acquireAccessToken(instance).then(accessToken => {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);
            headers.append('Content-type', 'application/x-www-form-urlencoded');

            const options: RequestInit = {
                method: "POST",
                mode: "cors",
                headers: headers
            };

            fetch(`${apiConfig.apis.windowsPilot}/${props.deviceName}?enable=${enable}`, options).then((value) => {
                if (value.ok) {
                    setEnabled(enable);
                }
            });
        });
    };

    return (
        <FormGroup>
            <FormControlLabel
                label={enabled === undefined ? "Loading..." : "Windows Update Pilot"}
                disabled={enabled !== true && enabled !== false}
                control={
                    <Switch
                        checked={enabled || false}
                        onChange={() => changeEnabled(!enabled)}
                    />
                }
            />
        </FormGroup>
    );
}