import { useEffect, useState } from "react";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { apiConfig } from "../authConfig";
import { acquireAccessToken } from "./MsalHelper";
import { IDevice } from "../interfaces/IDevice";
import { IDeviceBase } from "../interfaces/IDeviceBase";

interface WindowsHelloSwitchProps {
    device: IDevice | undefined;
};

export default function WindowsHelloSwitch(props: WindowsHelloSwitchProps) {
    const { instance } = useMsal();
    const [enabled, setEnabled] = useState<boolean | null>(null);

    useEffect(() => {
        setEnabled(null);

        if (props.device) {
            isEnabled();
        }
    }, [props.device]);

    const isEnabled = () => {
        acquireAccessToken(instance).then(accessToken => {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);

            const options: RequestInit = {
                method: "GET",
                mode: "cors",
                headers: headers
            };

            fetch(`${apiConfig.apis.windowsHello}/${props.device?.id}`, options).then((value) => {
                if (value.ok) {
                    return value.json();
                }
            }).then((response: boolean) => {
                setEnabled(response);
            });
        });
    };

    const changeEnabled = (enable: boolean) => {
        setEnabled(null);

        acquireAccessToken(instance).then(accessToken => {
            if (!props.device) {
                return;
            }

            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);
            headers.append('Content-type', 'application/json');

            var body: IDeviceBase = {
                displayName: props.device.displayName,
                id: props.device.id,
                trustType: props.device.trustType
            };

            const options: RequestInit = {
                method: "POST",
                mode: "cors",
                headers: headers,
                body: JSON.stringify(body)
            };

            fetch(`${apiConfig.apis.windowsHello}/${enable}`, options).then((value) => {
                if (value.ok) {
                    setEnabled(enable);
                }
            });
        });
    };

    return (
        <FormGroup>
            <FormControlLabel
                label={enabled === null ? "Loading..." : "Windows Hello for Business"}
                disabled={enabled === null}
                control={
                    <Switch
                        checked={enabled || false}
                        onChange={() => changeEnabled(!enabled)}
                    />
                }
            />
        </FormGroup>
    );
}