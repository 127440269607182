import { useEffect, useState } from "react";
import { Alert, Card, CardActions, CardContent, Typography } from "@mui/material";
import { apiConfig } from "../authConfig";
import { acquireAccessToken } from "./MsalHelper";
import { useMsal } from "@azure/msal-react";
import ConfirmDialog from "./ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import UpgradeIcon from '@mui/icons-material/Upgrade';

interface Windows11UpgradeCardProps {
    deviceName: string | null | undefined;
};

enum UpgradeStatus {
    FAILED,
    IN_PROGRESS,
    NOT_STARTED,
    SUCCESS
};

export default function Windows11UpgradeCard(props: Windows11UpgradeCardProps) {
    const { instance } = useMsal();

    const [isWin11Compatible, setIsWin11Compatible] = useState<boolean | null>();
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
    const [upgradeStatus, setUpgradeStatus] = useState<UpgradeStatus>(UpgradeStatus.NOT_STARTED);

    const checkWin11UpgradeEligiblility = () => {
        acquireAccessToken(instance).then(accessToken => {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);

            const options: RequestInit = {
                method: "GET",
                mode: "cors",
                headers: headers
            };

            fetch(`${apiConfig.apis.windows11}/${props.deviceName}/IsUpgradeable`, options).then((value) => {
                if (value.ok) {
                    return value.json();
                } else {
                    throw Error;
                }
            }).then((response: boolean) => {
                setIsWin11Compatible(response);
            }).catch(() => {
                setIsWin11Compatible(null);
            });
        });
    };

    const startUpgrade = () => {
        setUpgradeStatus(UpgradeStatus.IN_PROGRESS);

        acquireAccessToken(instance).then(accessToken => {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);

            const options: RequestInit = {
                method: "POST",
                mode: "cors",
                headers: headers
            };

            fetch(`${apiConfig.apis.windows11}/${props.deviceName}/Upgrade`, options).then((value) => {
                if (value.ok) {
                    setUpgradeStatus(UpgradeStatus.SUCCESS);
                } else {
                    setUpgradeStatus(UpgradeStatus.FAILED);
                }
            });
        });
    };

    useEffect(() => {
        if (props.deviceName) {
            checkWin11UpgradeEligiblility();
        } else {
            setIsWin11Compatible(false);
        }
    }, [props.deviceName]);

    return (
        <>
            <Card sx={{ minWidth: 275, overflow: 'initial' }}>
                <CardContent>
                    <Typography variant="h6" component="div">
                        Windows 11 Upgrade
                    </Typography>
                    <Typography variant="body2">
                        {isWin11Compatible === true ? "This device is eligible for the Windows 11 upgrade." : isWin11Compatible === false ? "This device is NOT eligible for the Windows 11 upgrade." : isWin11Compatible === null ? "Couldn't determine status." : "Checking status..."}
                    </Typography>
                    {upgradeStatus === UpgradeStatus.SUCCESS ? <Alert
                        severity="success"
                        sx={{ marginTop: 2 }}
                    >Upgrade successful.</Alert> : ""}
                    {upgradeStatus === UpgradeStatus.FAILED ? <Alert
                        severity="error"
                        sx={{ marginTop: 2 }}
                    >Upgrade failed.</Alert> : ""}
                </CardContent>
                {isWin11Compatible && (upgradeStatus === UpgradeStatus.IN_PROGRESS || upgradeStatus === UpgradeStatus.NOT_STARTED) ?
                    <CardActions>
                        <LoadingButton
                            loading={upgradeStatus === UpgradeStatus.IN_PROGRESS}
                            loadingPosition="start"
                            variant="text"
                            size="medium"
                            startIcon={<UpgradeIcon />}
                            onClick={() => setIsConfirmDialogOpen(true)}
                        >{upgradeStatus === UpgradeStatus.IN_PROGRESS ? "Upgrading..." : "Upgrade"}</LoadingButton>

                    </CardActions>
                    : ""}
            </Card>
            <ConfirmDialog
                message={`${props.deviceName} will be upgraded to Windows 11.`}
                onApprove={() => {
                    setIsConfirmDialogOpen(false);
                    startUpgrade();
                }}
                onReject={() => setIsConfirmDialogOpen(false)}
                open={isConfirmDialogOpen}
            />
        </>
    );
}