import { Box, Breadcrumbs, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IDevice } from '../interfaces/IDevice';

interface DialogHeaderProps {
    device: IDevice | null | undefined;
    icon: JSX.Element;
    title: string;
};

export default function DialogHeader(props: DialogHeaderProps) {
    return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Typography color="text.primary">{props.device?.hsvInfos?.hsvName || props.device?.displayName}</Typography>
            <Box sx={{ display: "flex", gap: 1 }}>{props.icon}<Typography color="text.primary">{props.title}</Typography></Box>
        </Breadcrumbs>
    );
}