import { IPublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { apiConfig, graphConfig } from "../authConfig";

export const acquireAccessToken = async (publicClientApplication: IPublicClientApplication, isGraphRequest: boolean = false): Promise<string | void> => {
  const account = publicClientApplication.getAllAccounts()[0];

  const accessTokenRequest = {
    scopes: isGraphRequest ? graphConfig.scopes : apiConfig.scopes,
    account: account
  };

  return await publicClientApplication.acquireTokenSilent(accessTokenRequest)
    .then(authenticationResult => {
      return authenticationResult.accessToken;
    }).catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        publicClientApplication.acquireTokenRedirect(accessTokenRequest);
      }
    });
}