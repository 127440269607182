import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

interface ConfirmDialogProps {
    message: string;
    onApprove(): any;
    onReject(): any;
    open: boolean;
};

export default function ConfirmDialog(props: ConfirmDialogProps) {
    return (
        <Dialog open={props.open} onClose={() => props.onReject()} maxWidth='lg' scroll='paper'>
            <DialogTitle>
                Confirm
            </DialogTitle>
            <DialogContent>
                {props.message}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => props.onReject()}>
                    Cancel
                </Button>
                <Button onClick={() => props.onApprove()}>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}