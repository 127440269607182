import { useEffect, useState } from "react";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import OsImageDropdown from "./OsImageDropdown";
import { IDevice } from "../interfaces/IDevice";
import AlbumIcon from '@mui/icons-material/Album';
import DialogHeader from "./DialogHeader";
import { acquireAccessToken } from "./MsalHelper";
import { useMsal } from "@azure/msal-react";
import { apiConfig } from "../authConfig";
import { IOsImage } from "../interfaces/IOsImage";
import { LoadingButton } from "@mui/lab";

interface OsImageChangeDialogProps {
    device: IDevice;
    showDialog: boolean;
    onClose(): any;
    onImageAssigned(imageName: string): any;
};

enum Status {
    READY,
    IN_PROGRESS,
    ERROR,
    SUCCESS
};

export default function OsImageAssignmentDialog(props: OsImageChangeDialogProps) {
    const { instance } = useMsal();

    const [selectedOsImage, setSelectedOsImage] = useState<IOsImage>();
    const [status, setStatus] = useState<Status>(Status.READY);

    useEffect(() => {
        setSelectedOsImage(undefined);
    }, [props.showDialog]);

    const assignOsImage = () => {
        if (status !== Status.READY) return;
        setStatus(Status.IN_PROGRESS);

        acquireAccessToken(instance).then(accessToken => {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);

            const options: RequestInit = {
                method: "POST",
                mode: "cors",
                headers: headers
            };

            fetch(`${apiConfig.apis.deviceManager}/SetOsImage/${props.device.displayName}/${selectedOsImage?.collectionId}`, options).then((value) => {
                if (value.ok) {
                    setStatus(Status.SUCCESS);
                    props.onImageAssigned(selectedOsImage?.displayName as string);
                } else {
                    setStatus(Status.ERROR);
                }
            }).catch(() => {
                setStatus(Status.ERROR);
            });
        });
    }

    return (
        <Dialog open={props.showDialog} onClose={() => { if (status !== Status.IN_PROGRESS) { props.onClose() } }}>
            <DialogTitle>
                <DialogHeader device={props.device} icon={<AlbumIcon />} title="OS image" />
            </DialogTitle>
            <DialogContent sx={{ paddingBottom: 1 }}>
                <div style={{ marginTop: 5 }}>
                    <OsImageDropdown
                        organizationalUnit={props.device.id.substring(props.device.id.indexOf(",") + 1)}
                        selectedOsImage={selectedOsImage}
                        setSelectedOsImage={(osImage) => setSelectedOsImage(osImage)}
                    />
                </div>
                {status === Status.ERROR && <Alert severity="error" sx={{ marginTop: 2 }}>Image could not be assigned.</Alert>}
                {status === Status.SUCCESS && <Alert severity="success" sx={{ marginTop: 2 }}>Image assigned.</Alert>}
            </DialogContent>
            <DialogActions>
                {status !== Status.IN_PROGRESS ? <Button
                    onClick={() => assignOsImage()}
                    disabled={!selectedOsImage || status !== Status.READY}
                >Assign</Button> : <LoadingButton
                    loading={true}
                    loadingPosition="start"
                    variant="text"
                    size="small"
                    startIcon={<AlbumIcon />}
                    disabled={true}
                >Assign</LoadingButton>}
                <Button
                    onClick={() => { if (status !== Status.IN_PROGRESS) { props.onClose() } }}
                >Close</Button>
            </DialogActions>
        </Dialog>
    );
}