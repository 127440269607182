import { useRef } from "react";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { apiConfig } from "../authConfig";
import { acquireAccessToken } from "./MsalHelper";
import DevicesView from "./DevicesView";
import { IDevice } from "../interfaces/IDevice";
import { IGroupedDevices } from "../interfaces/IGroupedDevices";
import { ClientAction } from "../enums/ClientAction";
import { KeyType } from "../enums/KeyType";

interface DeviceListProps {
    appRoles: string[];
    selectedDevice: IDevice | undefined;
    selectedOrganizationalUnit: string;
    searchTerm: string;
    groupedDevices: IGroupedDevices[] | undefined;
    setGroupedDevices(groupedDevices: IGroupedDevices[] | undefined): any;
    setSelectedDevice(computer: IDevice | undefined): any;
    onNewClicked(): any;
    onDetailsClicked(): any;
    onSoftwareClicked(): any;
    onFirewallClicked(): any;
    onClientActionClicked(action: ClientAction): any;
    onShowKeyClicked(keyType: KeyType): any;
};

export default function DeviceList(props: DeviceListProps) {
    const { instance } = useMsal();
    const abortController: React.MutableRefObject<AbortController> = useRef(new AbortController());

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (props.selectedOrganizationalUnit && props.searchTerm) {

        } else if (props.selectedOrganizationalUnit || props.searchTerm) {
            props.setGroupedDevices(undefined);

            var url = props.selectedOrganizationalUnit ?
                (props.selectedOrganizationalUnit.split('_')[0] === 'AzureOnly' ?
                    `${apiConfig.apis.azureAd}?computerGroup=${props.selectedOrganizationalUnit.split('_')[1]}` :
                    `${apiConfig.apis.devices}?organizationalUnit=${props.selectedOrganizationalUnit}`
                ) :
                `${apiConfig.apis.devices}/Search?query=${props.searchTerm}`;

            fetchData(url, false);
        } else {
            props.setGroupedDevices(undefined);
        }
    }, [props.selectedOrganizationalUnit, props.searchTerm]);

    const fetchData = (url: string, odataNextRequest: boolean) => {
        abortFetchRequests();

        acquireAccessToken(instance).then(accessToken => {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);

            const options: RequestInit = {
                method: "GET",
                mode: "cors",
                headers: headers,
                signal: getAbortSignal()
            };

            setLoading(true);

            fetch(url, options).then((value) => {
                if (value.status === 204) {
                    return null;
                } else {
                    return value.json();
                }
            }).then((response: IGroupedDevices | IGroupedDevices[]) => {
                if (props.selectedOrganizationalUnit) {
                    if (odataNextRequest) {
                        (response as IGroupedDevices).devices = (props.groupedDevices as IGroupedDevices[])[0].devices.concat((response as IGroupedDevices).devices);
                    }

                    props.setGroupedDevices([response as IGroupedDevices]);
                } else {
                    props.setGroupedDevices(response as IGroupedDevices[]);
                }

                setLoading(false);
            }).catch(_ => { });
        });
    };

    const refreshDeviceData = (computer: IDevice) => {
        acquireAccessToken(instance).then(accessToken => {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);

            const options: RequestInit = {
                method: "GET",
                mode: "cors",
                headers: headers
            };

            fetch(`${apiConfig.apis.devices}/Search?query=${computer.displayName}`, options).then((value) => {
                if (value.status === 204) {
                    return null;
                } else {
                    return value.json();
                }
            }).then((response: IGroupedDevices[]) => {
                if (response.length > 0 && props.groupedDevices) {
                    props.setGroupedDevices(props.groupedDevices.map(group => {
                        group.devices = group.devices.map(d => d.id === response[0].devices[0].id ? response[0].devices[0] : d)
                        return group;
                    }));
                }

                setLoading(false);
            });
        });
    }

    const initAbortController = () => {
        if (AbortController) {
            abortController.current = new AbortController();
        }
    };

    const getAbortSignal = () => {
        if (abortController.current && abortController.current.signal) {
            return abortController.current.signal;
        }
    }

    const abortFetchRequests = () => {
        if (abortController.current && abortController.current.abort as any) {
            abortController.current.abort();
            initAbortController();
        }
    }

    if (!props.selectedOrganizationalUnit && !props.searchTerm) {
        return <></>
    }

    return <>
        {!props.selectedOrganizationalUnit && !loading && <>
            <Typography key={'searchResultHeader'} variant="h6" color="text.primary" sx={{
                pl: { sm: 2 }
            }}>
                Search results for "{props.searchTerm}"
            </Typography>
            {props.groupedDevices?.length === 0 && <Typography key={'searchResultHeader'} sx={{
                pl: { sm: 2 }, pt: 2
            }}>
                No result
            </Typography>}
        </>}
        {(props.groupedDevices ? props.groupedDevices : [{ devices: [], path: [] } as IGroupedDevices]).map(x => <DevicesView
            appRoles={props.appRoles}
            key={x.path.join()}
            groupedDevices={x}
            isSearchResult={!props.selectedOrganizationalUnit}
            loadMoreData={() => {
                var url = new URL(`${apiConfig.apis.azureAd}?computerGroup=${props.selectedOrganizationalUnit.split('_')[1]}`);
                url.searchParams.set("odataNextLink", x.odataNextLink as string)
                fetchData(url.href, true)
            }}
            selectedDevice={props.selectedDevice}
            setSelectedDevice={(device: IDevice | undefined) => props.setSelectedDevice(device)}
            onClientActionClicked={props.onClientActionClicked}
            onDetailsClicked={props.onDetailsClicked}
            onFirewallClicked={props.onFirewallClicked}
            onNewClicked={props.onNewClicked}
            onShowKeyClicked={props.onShowKeyClicked}
            onSoftwareClicked={props.onSoftwareClicked}
            refreshDeviceData={(device: IDevice) => {
                setLoading(true);
                refreshDeviceData(device);
            }}
            loading={loading}
        />)}
    </>
}