import { useEffect, useState } from "react";
import { Breadcrumbs, IconButton, LinearProgress, Toolbar, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridCallbackDetails, GridPaginationModel, GridRowSelectionModel, GridSlots } from "@mui/x-data-grid";
import AddIcon from '@mui/icons-material/Add';
import { IGroupedDevices } from "../interfaces/IGroupedDevices";
import { IDevice } from "../interfaces/IDevice";
import GetColumns from "./DeviceListHelper";
import { ClientAction } from "../enums/ClientAction";
import { KeyType } from "../enums/KeyType";

interface DevicesViewProps {
    appRoles: string[];
    groupedDevices: IGroupedDevices;
    isSearchResult: boolean;
    selectedDevice: IDevice | undefined;
    setSelectedDevice(computer: IDevice | undefined): any;
    onNewClicked(): any;
    onDetailsClicked(): any;
    onSoftwareClicked(): any;
    onFirewallClicked(): any;
    onClientActionClicked(action: ClientAction): any;
    onShowKeyClicked(keyType: KeyType): any;
    refreshDeviceData(device: IDevice): any;
    loadMoreData(): any;
    loading: boolean;
};

export default function DevicesView(props: DevicesViewProps) {
    const columns = GetColumns({
        appRoles: props.appRoles,
        setSelectedDevice: props.setSelectedDevice,
        onDetailsClicked: props.onDetailsClicked,
        onSoftwareClicked: props.onSoftwareClicked,
        onFirewallClicked: props.onFirewallClicked,
        onClientActionClicked: props.onClientActionClicked,
        onShowKeyClicked: props.onShowKeyClicked,
        onRefreshDataClicked: (device: IDevice) => props.refreshDeviceData(device)
    });

    const [devicesCurrentPage, setDevicesCurrentPage] = useState<IDevice[]>([]);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 14 });

    useEffect(() => {
        setDevicesCurrentPage([]);
        setPaginationModel({ ...paginationModel, page: 0 })
    }, [JSON.stringify(props.groupedDevices.path)]);

    useEffect(() => {
        if (!props.groupedDevices.odataNextLink || props.groupedDevices.devices.length >= (paginationModel.page + 1) * paginationModel.pageSize) {
            var pcs: IDevice[] = props.groupedDevices.devices
                .slice(
                    paginationModel.page * paginationModel.pageSize,
                    paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                );

            setDevicesCurrentPage(pcs);
        } else {
            props.loadMoreData();
        }
    }, [paginationModel.page, props.groupedDevices.devices, props.loading]);

    return <>
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                height: 10
            }}
        >
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                <Breadcrumbs>
                    {props.groupedDevices.path.map(e => <Typography key={e} color="text.primary">{e}</Typography>)}
                </Breadcrumbs>
            </Typography>
            {!props.isSearchResult && <Tooltip title="New Computer">
                <IconButton onClick={() => props.onNewClicked()}>
                    <AddIcon />
                </IconButton>
            </Tooltip>}
        </Toolbar>
        <DataGrid
            rows={devicesCurrentPage || []}
            getRowId={(row: IDevice) => row.id}
            // rowCount for paginated data where more data is available adds page size to loaded amount because the total number is actually unknown
            rowCount={props.groupedDevices.devices.length > 0 ? props.groupedDevices.devices.length + (props.groupedDevices.odataNextLink ? paginationModel.pageSize : 0) : 0}
            rowSelectionModel={props.selectedDevice ? [props.selectedDevice.displayName] : []}
            onRowSelectionModelChange={(selectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => {
                if (selectionModel.length > 0) {
                    props.setSelectedDevice(devicesCurrentPage.find(c => c.id === selectionModel[0]))
                } else {
                    props.setSelectedDevice(undefined);
                }
            }}
            onRowClick={() => props.onDetailsClicked()}
            columns={columns}
            disableColumnSelector
            disableColumnMenu
            autoHeight {...devicesCurrentPage}
            paginationMode="server"
            paginationModel={paginationModel}
            pageSizeOptions={[paginationModel.pageSize]}
            onPaginationModelChange={setPaginationModel}
            slots={{
                loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
            }}
            loading={props.loading}
            {...devicesCurrentPage}
            hideFooter={props.isSearchResult ? true : false}
            localeText={{
                noRowsLabel: (props.isSearchResult && props.groupedDevices.path.length > 0) ? "Access denied" : "No rows"
            }}
            sx={{
                marginBottom: props.isSearchResult ? 3 : 0,
                '.MuiDataGrid-cell': {
                    display: "flex",
                    alignItems: "center"
                }
            }}
        />
    </>;
}