import { useMsal } from "@azure/msal-react";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import UndoIcon from '@mui/icons-material/Undo';
import { apiConfig } from "../authConfig";
import { acquireAccessToken } from "./MsalHelper";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { IDevice } from "../interfaces/IDevice";
import { IDeviceBase } from "../interfaces/IDeviceBase";
import { ClientAction } from "../enums/ClientAction";
import DialogHeader from "./DialogHeader";

interface ClientActionDialogProps {
    device: IDevice | undefined;
    clientAction: ClientAction | undefined;
    showDialog: boolean;
    onClose(deletedDeviceId?: string): any;
}

enum Status {
    NONE,
    IN_PROGRESS,
    SUCCESS
};

export default function ClientActionDialog(props: ClientActionDialogProps) {
    const { instance } = useMsal();

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [status, setStatus] = useState<Status>(Status.NONE);
    const [confirmTextValue, setConfirmTextValue] = useState<string>("");

    useEffect(() => {
        setErrorMessage(null);
        setStatus(Status.NONE);
        setConfirmTextValue("");
    }, [props.showDialog]);

    const triggerClientAction = () => {
        setStatus(Status.IN_PROGRESS);
        setErrorMessage(null);

        acquireAccessToken(instance).then(accessToken => {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);
            headers.append('Content-type', 'application/json');

            if (!props.device || props.clientAction === undefined) return;

            var body: IDeviceBase = {
                displayName: props.device.displayName,
                id: props.device.id,
                trustType: props.device.trustType
            };

            const options: RequestInit = {
                method: "POST",
                mode: "cors",
                headers: headers,
                body: JSON.stringify(body)
            };

            fetch(`${apiConfig.apis.deviceManager}/ClientAction/${props.clientAction}`, options).then((response) => {
                if (response.ok) {
                    setStatus(Status.SUCCESS);
                } else {
                    setStatus(Status.NONE);

                    response.json().then(data => {
                        setErrorMessage(data.detail ? data.detail : `Action failed.`);
                    }).catch(() => {
                        setErrorMessage(`Action failed.`);
                    });
                }
            }).catch(() => {
                setStatus(Status.NONE);
                setErrorMessage("Action failed.");
            });
        });
    };

    const onDialogClose = () => {
        props.onClose(status === Status.SUCCESS && props.clientAction === ClientAction.Delete ? props.device?.id : undefined)
    };

    let title = "";
    let verb = "";
    let icon: JSX.Element = <></>;

    switch (props.clientAction) {
        case ClientAction.AutopilotReset:
            title = "Autopilot Reset";
            verb = "resetted";
            icon = <UndoIcon />;
            break;
        case ClientAction.Delete:
            title = "Delete";
            verb = "deleted";
            icon = <DeleteForeverOutlinedIcon />;
            break;
        case ClientAction.Wipe:
            title = "Wipe";
            verb = "wiped";
            icon = <UndoIcon />;
            break;
        default:
            break;
    }

    return (
        <Dialog open={props.showDialog} onClose={() => { if (status !== Status.IN_PROGRESS) { onDialogClose(); } }}>
            <DialogTitle>
                <DialogHeader device={props.device} icon={icon} title={title} />
            </DialogTitle>
            <DialogContent sx={{ paddingBottom: 1 }}>
                {(status === Status.NONE || status === Status.IN_PROGRESS) && <Typography>
                    Device will be {verb}.
                </Typography>}
                {status === Status.SUCCESS && <Alert severity="success">Successfully {verb} {props.device?.displayName}.</Alert>}
                {errorMessage && <Alert severity="error" sx={{ marginTop: 1 }}>{errorMessage}</Alert>}
                {props.clientAction !== ClientAction.Delete && status !== Status.SUCCESS && <TextField
                    label="Enter value"
                    value={confirmTextValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmTextValue(event.target.value)}
                    helperText={`Type in '${title}' to confirm action.`}
                    variant="standard"
                    sx={{ marginTop: 1 }}
                />}
            </DialogContent>
            <DialogActions>
                {status !== Status.SUCCESS && <LoadingButton
                    loading={status === Status.IN_PROGRESS}
                    loadingPosition="start"
                    variant="contained"
                    size="small"
                    onClick={() => triggerClientAction()}
                    disabled={props.clientAction !== ClientAction.Delete && confirmTextValue.toLowerCase() !== title.toLowerCase()}
                >Confirm</LoadingButton>}
                <Button
                    onClick={() => onDialogClose()}
                    disabled={status === Status.IN_PROGRESS}
                >Close</Button>
            </DialogActions>
        </Dialog>
    );
}