import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Button, Grid, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import { IDeviceDetails } from "../interfaces/IDeviceDetails";
import { acquireAccessToken } from "./MsalHelper";
import { apiConfig } from "../authConfig";
import { IDevice } from "../interfaces/IDevice";
import { TrustType } from "../enums/TrustType";
import OsImageAssignmentDialog from "./OsImageAssignmentDialog";

interface DevicePropertiesProps {
    device: IDevice | undefined;
};

export default function DeviceProperties(props: DevicePropertiesProps) {
    const { instance } = useMsal();

    const [computerDetails, setComputerDetails] = useState<IDeviceDetails | null>();
    const [showOsImageAssignmentDialog, setShowOsImageAssignmentDialog] = useState<boolean>(false);

    useEffect(() => {
        if (props.device) {
            getComputerDetails();
        } else {
            setComputerDetails(undefined);
        }
    }, [props.device]);

    const getComputerDetails = () => {
        if (!props.device?.hsvInfos?.hsvName) {
            setComputerDetails(null);
            return;
        }

        acquireAccessToken(instance).then(accessToken => {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);

            const options: RequestInit = {
                method: "GET",
                mode: "cors",
                headers: headers
            };

            fetch(`${apiConfig.apis.devices}/Details?hsvName=${props.device?.hsvInfos?.hsvName}&deviceId=${props.device?.id}&trustType=${props.device?.trustType}`, options).then((value) => {
                if (value.ok) {
                    if (value.status === 204) {
                        return null;
                    } else {
                        return value.json();
                    }
                }
            }).then((response: IDeviceDetails) => {
                setComputerDetails(response);
            });
        });
    };

    if (computerDetails === null) {
        return <ListItem>
            <Typography>No information available.</Typography>
        </ListItem>;
    }

    const createGridGroup = (properties: { label: string, value: string | undefined, nestedElement?: JSX.Element }[]): JSX.Element => {
        return <Grid container spacing={2} sx={{ marginBottom: 6, paddingLeft: 1, paddingRight: 1 }}>
            {properties.map(v => <Grid item xs={12} md={6} key={Math.random()}>
                <div style={{ display: "flex" }}>
                    <TextField
                        label={v.label}
                        value={computerDetails === undefined ? "loading" : (v.value || v.nestedElement) ? v.value : "-"}
                        InputProps={{
                            readOnly: true,
                            startAdornment: v.nestedElement
                        }}
                        fullWidth
                        multiline
                    />
                    {v.label === "Image name"
                        && props.device?.trustType === TrustType.ServerAd
                        && !v.value && computerDetails !== undefined
                        && <Button
                            variant="text"
                            style={{ marginLeft: 5 }}
                            onClick={_ => setShowOsImageAssignmentDialog(true)}
                        >
                            Assign
                        </Button>}
                </div>
            </Grid>)}
        </Grid>;
    }

    return <>
        {createGridGroup([
            ...(props.device?.trustType !== TrustType.ServerAd ? [{ label: "Azure device name", value: props.device?.displayName }] : []),
            { label: "Operating system", value: props.device?.operatingSystem.name },
            { label: "OS version", value: props.device?.operatingSystem.version },
            { label: "Image name", value: computerDetails?.osImageName },
        ])}
        {createGridGroup([
            { label: "Model name", value: computerDetails?.modelName },
            { label: "Model number", value: computerDetails?.modelNumber },
            { label: "Serial number", value: computerDetails?.serialNumber },
            {
                label: "MAC addresses", value: "", nestedElement: (computerDetails?.macAddresses && computerDetails?.macAddresses.length > 0) ? <List disablePadding>
                    {computerDetails?.macAddresses.map(x => <ListItem disablePadding>
                        <ListItemText
                            primary={x.macAddress}
                            secondary={x.remark ? `(${x.remark})` : ""}
                            sx={{ margin: 0 }}
                        ></ListItemText>
                    </ListItem>)}
                </List> : undefined
            }
        ])}
        {createGridGroup([
            { label: "Location", value: computerDetails?.location },
            { label: "Machine", value: computerDetails?.machine },
            { label: "Comment", value: computerDetails?.comment },
            { label: "Stock", value: computerDetails?.stockName },
            { label: "Distribution state", value: computerDetails?.distributionState }
        ])}
        {props.device && <OsImageAssignmentDialog
            device={props.device}
            onClose={() => setShowOsImageAssignmentDialog(false)}
            showDialog={showOsImageAssignmentDialog}
            onImageAssigned={(imageName) => setComputerDetails({ ...computerDetails as IDeviceDetails, osImageName: imageName })}
        />}
    </>;
}