import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { acquireAccessToken } from "./MsalHelper";
import { apiConfig } from "../authConfig";
import { IOsImage } from "../interfaces/IOsImage";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";

interface OsImageDropdownProps {
    organizationalUnit: string;
    selectedOsImage: IOsImage | undefined;
    setSelectedOsImage(osImage: IOsImage): any;
};

export default function OsImageDropdown(props: OsImageDropdownProps) {
    const { instance } = useMsal();

    const [osImages, setOsImages] = useState<IOsImage[]>();

    useEffect(() => {
        setOsImages(undefined);
        getOsImages();
    }, [props.organizationalUnit]);

    const getOsImages = () => {
        acquireAccessToken(instance).then(accessToken => {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);
            headers.append('Content-type', 'application/json');

            const options: RequestInit = {
                method: "GET",
                mode: "cors",
                headers: headers
            };

            fetch(`${apiConfig.apis.deviceManager}/OsImages?organizationalUnit=${props.organizationalUnit}`, options).then((value) => {
                return value.json();
            }).then((response: IOsImage[]) => {
                setOsImages(response);
            });
        });
    }

    return (
        <FormControl fullWidth sx={{ paddingBottom: 2 }} error={osImages?.length === 0}>
            <InputLabel id="os-select-label">Select OS image</InputLabel>
            <Select
                labelId="os-select-label"
                value={props.selectedOsImage?.collectionId}
                label="Select OS image"
                onChange={(event) => props.setSelectedOsImage(osImages?.find(x => x.collectionId === event.target.value) as IOsImage)}
                disabled={osImages?.length === 0}
            >
                {osImages?.map(i => {
                    return <MenuItem key={i.collectionId} value={i.collectionId}>{i.displayName}</MenuItem>
                })}
            </Select>
            <FormHelperText>{osImages?.length === 0 ? "No images configured for this location" : ""}</FormHelperText>
        </FormControl>
    );
}