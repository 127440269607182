import { EventType, IPublicClientApplication } from "@azure/msal-browser";

export const handleLogin = (msalInstance: IPublicClientApplication) => {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    // Set active acccount on page load
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: any) => {
        // set active account after redirect
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
            const account = event.payload.account;
            msalInstance.setActiveAccount(account);
        }
    });

    // handle auth redired/do all initial setup for msal
    msalInstance.handleRedirectPromise().then(authResult => {
        // Check if user signed in 
        const account = msalInstance.getActiveAccount();
        if (!account) {
            // redirect anonymous user to login page 
            msalInstance.loginRedirect();
        }
    }).catch(err => {
        // TODO: Handle errors
    });
}