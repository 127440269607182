import { Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { styled, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ComputerIcon from '@mui/icons-material/Computer';
import AppsIcon from '@mui/icons-material/Apps';
import ShieldTwoToneIcon from '@mui/icons-material/ShieldTwoTone';
import Windows11UpgradeCard from "./Windows11UpgradeCard";
import WindowsHelloSwitch from "./WindowsHelloSwitch";
import WindowsUpdatePilotSwitch from "./WindowsUpdatePilotSwitch";
import DeviceProperties from "./DeviceProperties";
import { TrustType } from "../enums/TrustType";
import { IDevice } from "../interfaces/IDevice";
import { apiConfig } from "../authConfig";

interface DeviceDetailsProps {
    appRoles: string[];
    device: IDevice | undefined;
    isOpen: boolean;
    onClose(): any;
    onOpenSoftwareDialog(): any;
    onOpenFirewallDialog(): any;
};

// Header of computer info drawer
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export default function DeviceDetails(props: DeviceDetailsProps) {
    const theme = useTheme();

    return (
        <Drawer
            sx={{
                width: { sm: '100%', md: '50%' },
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: { sm: '100%', md: '50%' },
                },
            }}
            variant="temporary"
            anchor="right"
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <DrawerHeader>
                <IconButton onClick={() => props.onClose()}>
                    {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
                <ComputerIcon />
                <Typography sx={{ fontWeight: 'inherit', paddingLeft: 1 }}>{props.device?.hsvInfos?.hsvName || props.device?.displayName}</Typography>
            </DrawerHeader>
            <Divider />
            {/** If user has Device Lifecycle Manager role assigned these functions are hidden */}
            {!props.appRoles.includes(apiConfig.appRoles.deviceLifecycleManager) && <>
                <List>
                    <ListItem key="software" disablePadding>
                        <ListItemButton
                            onClick={() => props.onOpenSoftwareDialog()}
                        >
                            <ListItemIcon>
                                <AppsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Manage software" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key="firewall" disablePadding>
                        <ListItemButton
                            onClick={() => props.onOpenFirewallDialog()}
                        >
                            <ListItemIcon>
                                <ShieldTwoToneIcon />
                            </ListItemIcon>
                            <ListItemText primary="Firewall settings" />
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider />
                {/* Windows 11 Upgrade card and Update Pilot is only available for AD managed devices */}
                {props.device?.trustType === TrustType.ServerAd && <>
                    {props.device?.operatingSystem.name.match("Windows 10") ? <Windows11UpgradeCard //shows the info only for Windows 10 devices
                        deviceName={props.device.displayName}
                    /> : ""}
                    <ListItem>
                        <WindowsUpdatePilotSwitch deviceName={props.device?.displayName} />
                    </ListItem>
                </>}
                {props.device?.trustType === TrustType.AzureAd && <>
                    <ListItem>
                        <WindowsHelloSwitch device={props.device} />
                    </ListItem>
                </>}
            </>}
            <div style={{ marginTop: 50, display: "flex", flexDirection: "column" }}>
                <DeviceProperties device={props.device} />
            </div>
        </Drawer>
    )
}