import * as React from 'react';
import { useState, useEffect } from 'react';
import { Alert, Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Pagination, Snackbar, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { acquireAccessToken } from './MsalHelper';
import { apiConfig } from '../authConfig';
import { useMsal } from '@azure/msal-react';
import { Search, SearchIconWrapper, StyledInputBase } from './StyledSearch';
import AppsIcon from '@mui/icons-material/Apps';
import ApprovalIcon from '@mui/icons-material/Approval';
import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import DialogHeader from './DialogHeader';
import SoftwareConfirmDialog from './SoftwareConfirmDialog';
import { IComputerSoftware } from '../interfaces/IComputerSoftware';
import { ISoftwareChangeRequest } from '../interfaces/ISoftwareChangeRequest';
import { IDevice } from '../interfaces/IDevice';
import { ISoftware } from '../interfaces/ISoftware';


interface SoftwareDialogProps {
    device: IDevice | undefined;
    requireTicket: boolean;
    showDialog: boolean;
    onClose(): any;
}

export default function SoftwareDialog(props: SoftwareDialogProps) {
    const { instance } = useMsal();
    const timeout = React.useRef<any>();
    const inputRef = React.useRef<any>();
    const itemsPerPage = 30;
    const ref = React.useRef(null);

    // Dialogs
    const [showInstallConfirmDialog, setShowInstallConfirmDialog] = useState<boolean>(false);

    // Snackbars
    const [snackbarMessage, setSnackbarMessage] = useState<string>();
    const [errorSnackbarMessage, setErrorSnackbarMessage] = useState<string>();

    const [activeSoftwareTab, setActiveSoftwareTab] = useState<string>("1");
    const [computerSoftware, setComputerSoftware] = useState<IComputerSoftware>();
    const [selectedSoftwareNames, setSelectedSoftwareNames] = useState<string[]>([]);
    const [installedSoftwareFiltered, setInstalledSoftwareFiltered] = useState<ISoftware[]>([]);
    const [notInstalledSoftwareFiltered, setNotInstalledSoftwareFiltered] = useState<ISoftware[]>([]);
    const [page, setPage] = useState<number>(1);

    const getComputerSoftware = () => {
        acquireAccessToken(instance).then(accessToken => {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);

            const options: RequestInit = {
                method: "GET",
                mode: "cors",
                headers: headers
            };

            // Get computer software information
            fetch(`${apiConfig.apis.software}?trustType=${props.device?.trustType}&id=${props.device?.id}&name=${props.device?.displayName}`, options).then((value) => {
                if (value.ok && value.status !== 204) {
                    return value.json();
                }
            }).then((response: IComputerSoftware) => {
                setComputerSoftware(response);
            });
        });
    };

    const updateSoftwareInstallations = (ticketNumber: string) => {
        acquireAccessToken(instance).then(accessToken => {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);
            headers.append('Content-type', 'application/json');

            var selectedSoftware: ISoftware[] = selectedSoftwareNames.map(sn => computerSoftware?.listedSoftware.find(s => s.name === sn) as ISoftware);

            if (!props.device) {
                return;
            }

            var body: ISoftwareChangeRequest = {
                device: {
                    displayName: props.device.displayName,
                    id: props.device.id,
                    trustType: props.device.trustType
                },
                softwares: selectedSoftware,
                ticketNumber: ticketNumber
            };

            const options: RequestInit = {
                method: "POST",
                mode: "cors",
                headers: headers,
                body: JSON.stringify(body)
            };

            var url = activeSoftwareTab === "1" ? `${apiConfig.apis.software}/Uninstall` : `${apiConfig.apis.software}/Install`;

            // Call install/uninstall api
            fetch(url, options).then((value) => {
                if (value.ok) {
                    var snackbarText = "";

                    if (activeSoftwareTab === "1") {
                        if (computerSoftware) {
                            setComputerSoftware({
                                ...computerSoftware,
                                membershipCollectionIds: computerSoftware.membershipCollectionIds.filter(sid => !selectedSoftware.find(i => i.installCollectionId === sid))
                            });
                        }

                        snackbarText = "Uninstalled: "
                    } else {
                        if (computerSoftware) {
                            setComputerSoftware({
                                ...computerSoftware,
                                membershipCollectionIds: computerSoftware.membershipCollectionIds.concat(selectedSoftware.map(s => s.installCollectionId))
                            });
                        }

                        snackbarText = "Installed: "
                    }

                    snackbarText += selectedSoftware.map(s => s.name).join(', ');
                    setSnackbarMessage(snackbarText);
                    setSelectedSoftwareNames([]);
                } else {
                    setErrorSnackbarMessage(`Could not ${activeSoftwareTab === "1" ? "uninstall" : "install"} software.`);
                }
            }).catch(_ => {
                setErrorSnackbarMessage(`Could not ${activeSoftwareTab === "1" ? "uninstall" : "install"} software.`);
            });
        });
    };

    const getFilteredSoftware = (ms: number = 350) => {
        //Clear the previous timeout.
        clearTimeout(timeout.current);
        timeout.current = setTimeout(async () => {
            var filteredSoftware = ((!inputRef.current || inputRef.current.value.length < 1) ? computerSoftware?.listedSoftware : computerSoftware?.listedSoftware.filter(s => s.name.toLowerCase().includes(inputRef.current.value.toLowerCase()))) || [];

            setInstalledSoftwareFiltered(filteredSoftware.filter(s => computerSoftware?.membershipCollectionIds?.find(m => m === s.installCollectionId) !== undefined));
            setNotInstalledSoftwareFiltered(filteredSoftware.filter(s => computerSoftware?.membershipCollectionIds?.find(m => m === s.installCollectionId) === undefined && s.installCollectionId != null && s.assignable));
            setPage(1);
        }, ms);
    };

    useEffect(() => {
        getFilteredSoftware(0);
    }, [computerSoftware, props.showDialog]);

    // Get installed software of selected computer
    useEffect(() => {
        setActiveSoftwareTab("1");
        setComputerSoftware(undefined);

        if (props.device?.displayName && props.showDialog) {
            getComputerSoftware();
        }
    }, [props.device, props.showDialog]);

    useEffect(() => {
        setPage(1);
        setSelectedSoftwareNames([]);
        setSnackbarMessage(undefined);
        if (ref.current) {
            (ref.current as any).scrollTop = 0;
        }
    }, [activeSoftwareTab]);

    useEffect(() => {
        if (ref.current) {
            (ref.current as any).scrollTop = 0;
        }
    }, [page]);

    let numberOfPages: number = Math.ceil((activeSoftwareTab === "1" ? installedSoftwareFiltered : notInstalledSoftwareFiltered).length / itemsPerPage);
    let nonDefaultSoftware = computerSoftware?.listedSoftware
        .filter(s => computerSoftware?.membershipCollectionIds?.find(is => is === s.installCollectionId) !== undefined) // filter installed software
        .filter(i => !computerSoftware?.defaultCollectionIds?.includes(i.installCollectionId)) || []; // filter non-default software

    return (
        <Dialog open={props.showDialog} onClose={() => props.onClose()} maxWidth='lg' fullWidth={true} scroll='paper'>
            <DialogTitle>
                <DialogHeader device={props.device} icon={<AppsIcon />} title="Manage software" />
                <Search
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search..."
                        inputProps={{ 'aria-label': 'search' }}
                        inputRef={inputRef}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            getFilteredSoftware();
                        }}
                    />
                </Search>
                <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                <TabContext value={activeSoftwareTab}>
                    <Box>
                        <TabList onChange={(event: React.SyntheticEvent, newValue: string) => setActiveSoftwareTab(newValue)}>
                            <Tab label={`Installed (${installedSoftwareFiltered.length})`} value="1" disabled={!computerSoftware?.membershipCollectionIds} />
                            <Tab label={`Not installed (${notInstalledSoftwareFiltered.length})`} value="2" disabled={!computerSoftware?.membershipCollectionIds} />
                        </TabList>
                    </Box>
                </TabContext>
            </DialogTitle>
            <DialogContent id="dialogContent" ref={ref}>
                <TabContext value={activeSoftwareTab}>
                    <TabPanel value={activeSoftwareTab} sx={{ padding: 0 }}>
                        <div style={{ height: 600 }}>
                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                {(activeSoftwareTab === "1" ? installedSoftwareFiltered : notInstalledSoftwareFiltered).slice((page - 1) * itemsPerPage, ((page - 1) * itemsPerPage) + itemsPerPage).map(s => {

                                    var licensableField: string = s.comment?.split(';')[0].toLowerCase();
                                    var isLicensable = licensableField?.startsWith("yes") ? true : licensableField?.startsWith("no") ? false : undefined;
                                    var approvalBy = s.comment?.split(';')[1] ? s.comment.split(';')[1] : null;
                                    var isDefaultSoftware = computerSoftware?.defaultCollectionIds?.includes(s.installCollectionId);

                                    let checkboxIsChecked = selectedSoftwareNames.indexOf(s.name) !== -1;
                                    let immutableInstallationState = activeSoftwareTab === "1" ? (!s.uninstallCollectionId || !s.assignable) : !s.installCollectionId;

                                    return <ListItem alignItems="flex-start" key={s.name} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                        <ListItemButton
                                            role={undefined}
                                            dense
                                            onClick={() => {
                                                if (!immutableInstallationState) {
                                                    checkboxIsChecked ? setSelectedSoftwareNames(selectedSoftwareNames.filter(i => i !== s.name))
                                                        : setSelectedSoftwareNames(ssn => [...ssn, s.name])
                                                }
                                            }}>
                                            <ListItemIcon>
                                                <Checkbox edge="start" tabIndex={-1} checked={checkboxIsChecked} disabled={immutableInstallationState} disableRipple />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<React.Fragment>
                                                    <Typography
                                                        sx={{ display: 'inline', marginRight: 1 }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        {s.name}
                                                    </Typography>
                                                    {isDefaultSoftware ? <Chip label="Default" size="small" color="success" icon={<SettingsIcon />} sx={{ marginRight: 1, marginBottom: 1 }} /> : ""}
                                                    {isLicensable === true ? <Chip label="Licensable" size="small" color="warning" icon={<KeyIcon />} sx={{ marginRight: 1, marginBottom: 1 }} /> : ""}
                                                    {isLicensable === false ? <Chip label="License-free" size="small" color="default" icon={<KeyOffIcon />} sx={{ marginRight: 1, marginBottom: 1 }} /> : ""}
                                                    {approvalBy !== null ? <Chip label={`Approval by ${approvalBy}`} size="small" color="info" icon={<ApprovalIcon />} sx={{ marginRight: 1, marginBottom: 1 }} /> : ""}
                                                </React.Fragment>}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                        //color="text.primary"
                                                        >
                                                            {s?.comment?.split(';')[4]}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                })}
                                {numberOfPages > 1 ?
                                    <Pagination
                                        count={numberOfPages}
                                        page={page}
                                        onChange={(e, p) => setPage(p)}
                                        sx={{ marginTop: 2 }}
                                    />
                                    : ""}
                            </List>
                        </div>
                    </TabPanel>
                </TabContext>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()}>Close</Button>
                <Button disabled={nonDefaultSoftware.length < 1} onClick={() => {
                    navigator.clipboard.writeText(nonDefaultSoftware.map(i => i.name).join("\n"));
                }}>Copy non-default</Button>
                <Button
                    disabled={selectedSoftwareNames.length < 1}
                    onClick={() => setShowInstallConfirmDialog(true)}
                >
                    {activeSoftwareTab === "1" ? `Uninstall (${selectedSoftwareNames.length})` : `Install (${selectedSoftwareNames.length})`}
                </Button>
            </DialogActions>
            <Snackbar
                open={snackbarMessage !== undefined}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                autoHideDuration={6000}
                onClose={() => setSnackbarMessage(undefined)}
                message={snackbarMessage}
            />
            <Snackbar
                open={errorSnackbarMessage !== undefined}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                autoHideDuration={6000}
                onClose={() => setErrorSnackbarMessage(undefined)}
                message={snackbarMessage}
            >
                <Alert severity="error" sx={{ width: '100%' }}>
                    {errorSnackbarMessage}
                </Alert>
            </Snackbar>
            <SoftwareConfirmDialog
                action={activeSoftwareTab === "1" ? "uninstalled" : "installed"}
                onApprove={(ticketNumber: string) => {
                    if (showInstallConfirmDialog) {
                        setShowInstallConfirmDialog(false);
                        updateSoftwareInstallations(ticketNumber);
                    }
                }}
                onReject={() => setShowInstallConfirmDialog(false)}
                open={showInstallConfirmDialog}
                requireTicket={props.requireTicket}
                selectedSoftwareNames={selectedSoftwareNames}
            />
        </Dialog>
    )
}