import * as React from 'react';
import { useState, useEffect } from 'react';
import { AppBar, Avatar, Box, CssBaseline, Divider, IconButton, ListItemText, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { graphConfig, apiConfig, helpUrl, logsUrl } from "./authConfig";
import { acquireAccessToken } from './components/MsalHelper';
import SoftwareDialog from './components/SoftwareDialog';
import AddDeviceDialog from './components/AddDeviceDialog';
import DeviceDetails from './components/DeviceDetails';
import NavigationDrawer, { drawerWidth } from './components/NavigationDrawer';
import GlobalSearchField from './components/GlobalSearchField';
import DeviceList from './components/DeviceList';
import FirewallDialog from './components/FirewallDialog';
import ClientActionDialog from './components/ClientActionDialog';
import KeyDialog from './components/KeyDialog';
import { IDevice } from './interfaces/IDevice';
import { IGroupedDevices } from './interfaces/IGroupedDevices';
import { ClientAction } from './enums/ClientAction';
import { KeyType } from './enums/KeyType';

export default function App() {
  const { instance, accounts } = useMsal();
  const preferredColorPalette = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light';

  // Authentication variables
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [appRoles, setAppRoles] = useState<string[]>([]);

  // Loaded data
  const [profilePhoto, setProfilePhoto] = useState<string>();
  const [groupedDevices, setGroupedDevices] = useState<IGroupedDevices[] | undefined>();

  // Dialog states
  const [showDeviceDetails, setShowDeviceDetails] = useState<boolean>(false);
  const [showSoftwareDialog, setShowSoftwareDialog] = useState<boolean>(false);
  const [showFirewallDialog, setShowFirewallDialog] = useState<boolean>(false);
  const [showNewDialog, setShowNewDialog] = useState<boolean>(false);
  const [showClientActionDialogAction, setShowClientActionDialogAction] = useState<ClientAction>();
  const [openKeyDialogType, setOpenKeyDialogType] = useState<KeyType | null>(null);

  // Selected elements
  const [globalSearchTerm, setglobalSearchTerm] = useState<string>("");
  const [selectedOrganizationalUnit, setSelectedOrganizationalUnit] = useState<string>(null as any);
  const [selectedDevice, setSelectedDevice] = useState<IDevice>();

  // Other
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [paletteMode, setPaletteMode] = useState<'light' | 'dark'>(localStorage.getItem('bcm-custom-theme') as any || preferredColorPalette);

  // Make initial api requests after user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      setAppRoles(accounts[0].idTokenClaims?.roles || []);

      acquireAccessToken(instance, true).then(accessToken => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);

        const options: RequestInit = {
          method: "GET",
          mode: "cors",
          headers: headers
        };

        // Get user profile photo
        fetch(graphConfig.myPhotoEndpoint, options).then((value) => {
          return value.blob()
        }).then((blob) => {
          const url = window.URL || window.webkitURL;
          const blobUrl = url.createObjectURL(blob);
          setProfilePhoto(blobUrl);
        });
      });
    }
  }, [isAuthenticated, instance]);

  if (!useIsAuthenticated()) {
    return <div />
  } else if (isAuthenticated === false) {
    setIsAuthenticated(true);
  }

  return (
    <ThemeProvider theme={createTheme({
      palette: {
        mode: paletteMode,
      }
    })}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          color='default'
          sx={{
            //width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              Hello, {accounts[0]?.name}!
            </Typography>
            <GlobalSearchField
              activeSearchTerm={globalSearchTerm}
              onSearchExecuted={(searchValue: string) => {
                setSelectedOrganizationalUnit(null as any);
                setglobalSearchTerm(searchValue);
              }}
            />
            <>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  setAnchorEl(event.currentTarget);
                }}
                color="inherit"
              >
                <Avatar src={profilePhoto} sx={{ width: 32, height: 32 }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={() => {
                  setAnchorEl(null);
                }}
              >
                <MenuItem unselectable='on'>
                  <Avatar src={profilePhoto} sx={{ width: 36, height: 36, marginRight: 2 }} />
                  <Box>
                    <Typography sx={{ margin: 0 }} variant="subtitle1" gutterBottom>
                      {accounts[0]?.name}
                    </Typography>
                    <Typography sx={{ margin: 0 }} variant="body2" gutterBottom>
                      {accounts[0]?.username}
                    </Typography>
                  </Box>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => window.open(helpUrl, '_blank')}>Help</MenuItem>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <MenuItem onClick={() => {
                    var newPaletteMode: any = paletteMode === 'light' ? 'dark' : 'light';
                    setPaletteMode(newPaletteMode);
                    localStorage.setItem('bcm-custom-theme', newPaletteMode);
                  }}>
                    <ListItemText>Switch to {paletteMode === 'light' ? 'dark' : 'light'} mode</ListItemText>
                  </MenuItem>
                  <IconButton sx={{ marginRight: 2 }} edge="end" aria-label="delete" title='Reset to browser default' onClick={() => {
                    setPaletteMode(preferredColorPalette);
                    localStorage.removeItem('bcm-custom-theme');
                  }}>
                    <RestartAltIcon />
                  </IconButton>
                </Box>
                {appRoles?.includes(apiConfig.appRoles.admin) && <MenuItem onClick={() => window.open(logsUrl, '_blank')}>Logs (Admin)</MenuItem>}
                <MenuItem onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: null })}>Sign out</MenuItem>
              </Menu>
            </>
          </Toolbar>
        </AppBar>
        <NavigationDrawer
          isMobileDrawerOpen={mobileNavOpen}
          paletteMode={paletteMode}
          selectedOrganizationalUnit={selectedOrganizationalUnit}
          onMobileDrawerOpenToggled={() => setMobileNavOpen(!mobileNavOpen)}
          onOrganizationalUnitSelected={(organizationalUnit: string) => {
            if (organizationalUnit) {
              setglobalSearchTerm("");
            }

            setSelectedOrganizationalUnit(organizationalUnit);
          }}
        />
        {<Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <DeviceList
            appRoles={appRoles}
            selectedDevice={selectedDevice}
            selectedOrganizationalUnit={selectedOrganizationalUnit}
            searchTerm={globalSearchTerm}
            groupedDevices={groupedDevices}
            setGroupedDevices={(groupedDevices) => setGroupedDevices(groupedDevices)}
            setSelectedDevice={(device: IDevice | undefined) => setSelectedDevice(device)}
            onNewClicked={() => setShowNewDialog(true)}
            onDetailsClicked={() => setShowDeviceDetails(true)}
            onSoftwareClicked={() => { setShowSoftwareDialog(true) }}
            onFirewallClicked={() => { setShowFirewallDialog(true) }}
            onClientActionClicked={(action: ClientAction) => { setShowClientActionDialogAction(action) }}
            onShowKeyClicked={(keyType: KeyType) => { setOpenKeyDialogType(keyType) }}
          />
          <DeviceDetails
            appRoles={appRoles}
            device={selectedDevice}
            isOpen={showDeviceDetails}
            onClose={() => setShowDeviceDetails(false)}
            onOpenSoftwareDialog={() => setShowSoftwareDialog(true)}
            onOpenFirewallDialog={() => setShowFirewallDialog(true)}
          />
          <SoftwareDialog
            device={selectedDevice}
            requireTicket={!appRoles?.includes(apiConfig.appRoles.admin)}
            showDialog={showSoftwareDialog}
            onClose={() => setShowSoftwareDialog(false)}
          />
          <FirewallDialog
            device={selectedDevice}
            showDialog={showFirewallDialog}
            onClose={() => setShowFirewallDialog(false)}
          />
          <AddDeviceDialog
            organizationalUnit={selectedOrganizationalUnit}
            showDialog={showNewDialog}
            onClose={() => setShowNewDialog(false)}
          />
          <ClientActionDialog
            device={selectedDevice}
            clientAction={showClientActionDialogAction}
            showDialog={showClientActionDialogAction !== undefined}
            onClose={(deletedDeviceId?: string) => {
              setShowClientActionDialogAction(undefined);

              if (deletedDeviceId && groupedDevices) {
                setGroupedDevices(groupedDevices.map(group => {
                  group.devices = group.devices.filter(d => d.id !== deletedDeviceId);
                  return group;
                }));
              }
            }}
          />
          <KeyDialog
            device={selectedDevice}
            showDialog={openKeyDialogType !== null}
            onClose={() => setOpenKeyDialogType(null)}
            keyType={openKeyDialogType}
          />
        </Box>}
      </Box>
    </ThemeProvider>
  );
}