import { GridActionsCellItem, GridActionsColDef, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import AppsIcon from '@mui/icons-material/Apps';
import ComputerIcon from '@mui/icons-material/Computer';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import KeyIcon from '@mui/icons-material/Key';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import ShieldTwoToneIcon from '@mui/icons-material/ShieldTwoTone';
import UndoIcon from '@mui/icons-material/Undo';
import { IDevice } from "../interfaces/IDevice";
import { TrustType } from "../enums/TrustType";
import { ClientAction } from "../enums/ClientAction";
import { KeyType } from "../enums/KeyType";
import { apiConfig } from "../authConfig";

interface ColumnProps {
    appRoles: string[];
    setSelectedDevice(device: IDevice | undefined): any;
    onDetailsClicked(): any;
    onSoftwareClicked(): any;
    onFirewallClicked(): any;
    onClientActionClicked(action: ClientAction): any;
    onShowKeyClicked(keyType: KeyType): any;
    onRefreshDataClicked(device: IDevice): any;
};

export default function GetColumns(props: ColumnProps): (GridColDef | GridActionsColDef)[] {
    return [
        {
            field: 'icon', headerName: '', width: 50, sortable: false, renderCell: (params: GridRenderCellParams<Date>) => (
                <ComputerIcon />
            )
        },
        {
            field: 'hsvName', headerName: 'Name', width: 150, sortable: false, valueGetter: (value, row: IDevice) =>
                row.hsvInfos?.hsvName ? row.hsvInfos?.hsvName : row.displayName
        },
        {
            field: 'actions',
            type: 'actions',
            width: 50,
            getActions: (params: GridRowParams) => {
                var actions = [
                    <GridActionsCellItem
                        icon={<ReadMoreIcon />}
                        onClick={() => {
                            props.setSelectedDevice(params.row);
                            props.onDetailsClicked();
                        }}
                        label="More"
                        showInMenu
                    />
                ];

                {/** If user has Device Lifecycle Manager role assigned these functions are hidden */ }
                if (!props.appRoles.includes(apiConfig.appRoles.deviceLifecycleManager)) {
                    actions.push(
                        <GridActionsCellItem
                            icon={<AppsIcon />}
                            onClick={() => {
                                props.setSelectedDevice(params.row);
                                props.onSoftwareClicked();
                            }}
                            label="Software"
                            showInMenu
                        />
                    );

                    actions.push(
                        <GridActionsCellItem
                            icon={<ShieldTwoToneIcon />}
                            onClick={() => {
                                props.setSelectedDevice(params.row);
                                props.onFirewallClicked();
                            }}
                            label="Firewall settings"
                            showInMenu
                        />
                    );

                    if ((params.row as IDevice).trustType === TrustType.AzureAd) {
                        actions.push(
                            <GridActionsCellItem
                                icon={<UndoIcon />}
                                onClick={() => {
                                    props.setSelectedDevice(params.row);
                                    props.onClientActionClicked(ClientAction.AutopilotReset);
                                }}
                                label="Autopilot Reset"
                                showInMenu
                            />
                        );

                        actions.push(
                            <GridActionsCellItem
                                icon={<KeyIcon />}
                                onClick={() => {
                                    props.setSelectedDevice(params.row);
                                    props.onShowKeyClicked(KeyType.LocalAdminPwd);
                                }}
                                label="Show local admin password"
                                showInMenu
                            />
                        );
                    } else if ((params.row as IDevice).trustType === TrustType.Hybrid) {
                        actions.push(
                            <GridActionsCellItem
                                icon={<UndoIcon />}
                                onClick={() => {
                                    props.setSelectedDevice(params.row);
                                    props.onClientActionClicked(ClientAction.Wipe);
                                }}
                                label="Wipe"
                                showInMenu
                            />
                        );
                    }

                    if ((params.row as IDevice).trustType === TrustType.AzureAd || (params.row as IDevice).trustType === TrustType.Hybrid) {
                        actions.push(
                            <GridActionsCellItem
                                icon={<LockOpenIcon />}
                                onClick={() => {
                                    props.setSelectedDevice(params.row);
                                    props.onShowKeyClicked(KeyType.RecoveryKey);
                                }}
                                label="Show Recovery key"
                                showInMenu
                            />
                        );
                    }
                }

                actions.push(
                    <GridActionsCellItem
                        icon={<RefreshIcon />}
                        onClick={() => {
                            props.onRefreshDataClicked(params.row);
                        }}
                        label="Refresh"
                        showInMenu
                    />
                );

                actions.push(
                    <GridActionsCellItem
                        icon={<DeleteForeverOutlinedIcon />}
                        onClick={() => {
                            props.setSelectedDevice(params.row);
                            props.onClientActionClicked(ClientAction.Delete);
                        }}
                        label="Delete"
                        showInMenu
                        // disable delete option based on distribution state)
                        disabled={!!(params.row as IDevice).hsvInfos?.distributionState}
                    />
                );

                return actions;
            }
        },
        {
            field: 'employee', headerName: 'Employee name', width: 200, sortable: false, valueGetter: (value, row: IDevice) =>
                row.hsvInfos?.employee ? `${row.hsvInfos?.employee?.firstName} ${row.hsvInfos?.employee?.lastName}` : ""
        },
        {
            field: 'employeeId', headerName: 'Employee id', width: 150, sortable: false, valueGetter: (value, row: IDevice) =>
                row.hsvInfos?.employee?.employeeID
        },
        {
            field: 'department', headerName: 'Department', width: 150, sortable: false, valueGetter: (value, row: IDevice) =>
                row.hsvInfos?.department
        },
        {
            field: 'machine', headerName: 'Machine', width: 200, sortable: false, valueGetter: (value, row: IDevice) =>
                row.hsvInfos?.machine
        }
    ];
};