import { SimpleTreeView, TreeItem, treeItemClasses } from "@mui/x-tree-view";
import { Box, Drawer, Toolbar, Typography } from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import { IComputerGroup } from "../interfaces/IComputerGroup";
import { IOrganizationalUnit } from "../interfaces/IOrganizationalUnit";
import { useEffect, useState } from "react";
import { apiConfig } from "../authConfig";
import { acquireAccessToken } from "./MsalHelper";
import { useMsal } from "@azure/msal-react";
import logo from '../blum_logo.svg';
import AzureLogo from '../azure.svg';

export const drawerWidth = 300;

interface NavigationDrawerProps {
    isMobileDrawerOpen: boolean;
    paletteMode: 'light' | 'dark';
    selectedOrganizationalUnit: string;
    onMobileDrawerOpenToggled(): any;
    onOrganizationalUnitSelected(organizationalUnit: string | null): any;
};

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.content}`]: {
        padding: theme.spacing(0.1, 1)
    }
}));

// Converts the computer group into a tree item
const getComputerGroupTreeItem = (computerGroup: IComputerGroup) => {
    const azureOnlyItem = <StyledTreeItem
        itemId={`AzureOnly_${computerGroup.name}`}
        key={`AzureOnly_${computerGroup.name}`}
        itemType="group"
        label={
            <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                <img src={AzureLogo} height={20} style={{ marginRight: 8 }} />
                <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                    Azure only
                </Typography>
            </Box>
        }
    />;

    const treeItem = <StyledTreeItem
        itemId={computerGroup.name}
        key={computerGroup.name}
        itemType="group"
        label={
            <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                <Box component={FolderIcon} fontSize={20} color="inherit" sx={{ mr: 1 }} />
                <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                    {computerGroup.name}
                </Typography>
            </Box>
        }
        children={[azureOnlyItem].concat(computerGroup.organizationalUnits.map(o => getOrganizationalUnitTreeNode(o)))}
    />;

    return treeItem;
};

// Converts the organizational unit into a tree item
const getOrganizationalUnitTreeNode = (organizationalUnit: IOrganizationalUnit) => {
    const treeItem = <StyledTreeItem
        itemId={organizationalUnit.distinguishedName}
        key={organizationalUnit.distinguishedName}
        itemType="group"
        label={
            <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                <Box component={FolderIcon} fontSize={20} color="inherit" sx={{ mr: 1 }} />
                <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                    {organizationalUnit.name}
                </Typography>
            </Box>
        }
        children={organizationalUnit.children.map(o => getOrganizationalUnitTreeNode(o))}
    />;

    return treeItem;
}

export default function NavigationDrawer(props: NavigationDrawerProps) {
    const { instance } = useMsal();
    const theme = useTheme();

    const [initialized, setInitialized] = useState<boolean>(false);
    const [computerGroups, setComputerGroups] = useState<IComputerGroup[]>([]);
    const [expandedNodeIds, setExpandedNodeIds] = useState<string[]>([]);
    const [selectedTreeNodeId, setSelectedTreeNodeId] = useState<string>(null as any);

    const handleDrawerToggle = () => {
        props.onMobileDrawerOpenToggled();
    };

    useEffect(() => {
        setSelectedTreeNodeId(props.selectedOrganizationalUnit);
    }, [props.selectedOrganizationalUnit]);

    useEffect(() => {
        var computerGroup: IComputerGroup | undefined = computerGroups.find(cg => cg.name === selectedTreeNodeId);

        if (computerGroup) {
            // Load organizational units for selected computer group
            props.onOrganizationalUnitSelected(null);

            acquireAccessToken(instance).then(accessToken => {
                const headers = new Headers();
                const bearer = `Bearer ${accessToken}`;

                headers.append("Authorization", bearer);

                const options: RequestInit = {
                    method: "GET",
                    mode: "cors",
                    headers: headers
                };

                if ((computerGroup as IComputerGroup).organizationalUnits.length < 1) {
                    // Get organizational units for selected computer group
                    fetch(`${apiConfig.apis.organizationalUnits}?computerGroup=${selectedTreeNodeId}`, options).then((value) => {
                        return value.json();
                    }).then((organizationalunits: IOrganizationalUnit[]) => {
                        let newComputerGroups = [...computerGroups];
                        newComputerGroups[newComputerGroups.findIndex(cg => cg.name === selectedTreeNodeId)].organizationalUnits = organizationalunits;

                        setComputerGroups(newComputerGroups);
                        setExpandedNodeIds(oldExpandedNodeIds => [...oldExpandedNodeIds, selectedTreeNodeId]);
                    });
                }
            });
        } else {
            // pass selected organizational unit to parent
            props.onOrganizationalUnitSelected(selectedTreeNodeId);
        }
    }, [selectedTreeNodeId]);

    useEffect(() => {
        acquireAccessToken(instance).then(accessToken => {
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);

            const options: RequestInit = {
                method: "GET",
                mode: "cors",
                headers: headers
            };

            // Get computer groups
            fetch(apiConfig.apis.groups, options).then((value) => {
                return value.json();
            }).then((computerGroups: string[]) => {
                setComputerGroups(computerGroups.sort().map(c => { return { name: c, organizationalUnits: [] } }));
            }).finally(() => {
                setInitialized(true);
            });
        });
    }, []);

    const body = (
        <>
            <Toolbar sx={{
                background: props.paletteMode === 'light' ? theme.palette.grey[100] : "#272727",
                boxShadow: "rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px",
                marginBottom: 2
            }} >
                <img src={logo} alt="Blum" height={45} style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => window.location.reload()} />
                <Typography variant="subtitle1" fontWeight={600} component="div" sx={{ flexGrow: 1, lineHeight: 1.2 }}>
                    Computer Management
                </Typography>
            </Toolbar>
            {initialized ? <SimpleTreeView
                aria-label="menu"
                slots={{ collapseIcon: ExpandMoreIcon, expandIcon: ChevronRightIcon }}
                sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                children={computerGroups.map(o => getComputerGroupTreeItem(o))}
                selectedItems={selectedTreeNodeId}
                expandedItems={expandedNodeIds}
                multiSelect={false}
                onSelectedItemsChange={(_: any, nodeId: string | null) => setSelectedTreeNodeId(nodeId as string)}
                onExpandedItemsChange={(_: any, nodeIds: string[]) => setExpandedNodeIds(nodeIds)}
            /> : <Typography variant="body2" sx={{ marginLeft: 3 }}>
                Loading...
            </Typography>}
        </>
    );

    return <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
            variant="temporary"
            open={props.isMobileDrawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
        >
            {body}
        </Drawer>
        <Drawer
            variant="permanent"
            sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
        >
            {body}
        </Drawer>
    </Box>
}